import React from "react"
import SEO from "../components/seo"

export default class AudioTest extends React.Component {

    componentDidMount() {
      var createPlayer = require('web-audio-player')

      var audio = createPlayer('/audio/hide.mp3')

      var i = 0
      audio.on('load', () => {
        console.log('Audio loaded...')
        
        // start playing audio file
        audio.loop = true
        audio.play()
        
        // and connect your node somewhere, such as
        // the AudioContext output so the user can hear it!
        audio.node.connect(audio.context.destination)
      })
      
      audio.on('ended', () => {
        console.log('Audio ended...')
      })

      audio.on('progress', () => {
        console.log(i++)
      })
    }
  
    render() {
      return (
        <div id="app">
            <SEO />
            yo
            <div className="loading"></div>
        </div>
      )
    }}   